<template>
    <div>
        <AppHeader />
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-lg-5 pt-lg-0 col-xl-12">
                    <router-view :locale="locale" :key="$route.fullPath"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueI18n from 'vue-i18n';
    import english from './locales/en-US';
    import Hebrew from "./locales/he-IL";
    import AppHeader from './components/AppHeader.vue';
    import { mapState } from "vuex";

    const locales = {
        'en-US': english,
        'he-IL': Hebrew
    };

    Vue.use(VueI18n);
    export const i18n = new VueI18n({
        locale: 'en-US',
        fallbackLocale: 'en-US',
        silentTranslationWarn: true,
        messages: {
            'en-US': locales['en-US'].messages,
            'he-IL': locales['he-IL'].messages
        },
    });

    export default {
        name: 'Localization',
        components: {
            AppHeader
        },
        mounted() {
            this.$i18n.locale = this.locale;
        },
        computed: {
            ...mapState(['locale']),
        },
        watch: {
            locale(val) {
                // this.$i18n.locale = val;
            },
        }
    };
</script>
