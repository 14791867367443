const titles = {
    action: 'פְּעוּלָה',
    title: 'כותרת',
    addNewTest: 'הוסף בדיקה חדשה',
    idNumber: "מספר תעודת זהות",
    dob: "תאריך לידה",
    enterToSearch: "הזן לחיפוש",
    edit: "ערוך",
    delete: "מחק",
    show: "הצג",
    birthday: "יום הולדת",
    gender: "מגדר",
    dateCompleted: "תאריך הושלם",
    tests: 'בדיקות',
    backToReports: "חזרה לדוחות",
    report: "דווח",
    close: "סגור",
    showCodes: "הצג קודים",
    generateReport: "הפקת דוח",
    logout: "יציאה",
    cancel: "ביטול",
    createNewTest: "צור מבחן חדש",
    numberOfQuestions: "מספר שאלות",
    name: "שם",
    email: "אימייל",
    isVerified: "האם מאומת?",
    isVisitFormLink: "האם טופס קישור?",
    users: "משתמשים",
    yes: "כן",
    no: "לא",
    back: "חזרה",
    role: "תפקיד",
    isLogin: "האם התחברות?",
    fullName: "שם מלא",
    password: "סיסמה",
    passwordConfirmation: "אישור סיסמה",
    saveAndClose: "שמור וסגור",
    user: "משתמש",
    addNewUser: "הוסף משתמש חדש",
    submit: "שלח",
    backToLogin: "חזרה לכניסה",
    forgotPassword: "שכחת את הסיסמה שלך?",
    forgotPasswordMsg: "הזן את כתובת הדוא’ל שלך ואנו נשלח לך קישור לאיפוס הסיסמה שלך",
    isActive: "האם פעיל?",
    signIn: "כניסה",
    rememberMe: "זכור אותי",
    login: "התחבר",
    forgotYourPassword: "שכחת את הסיסמה שלך?",
    or: "או",
    newToInklyze: "חדש ל-Inklyze?",
    signUp: "הירשם",
    alreadyHavingAccount: "כבר יש לך חשבון?",
    pass: "עבר",
    otp: "OTP",
    next: "הבא",
    verifyYourEmail: "אמת את האימייל שלך",
    weSentVerificationCode: "שלחנו קוד אימות בן 4 ספרות אל",
    didNotGetCode: "לא קיבלת קוד?",
    secondsLeft: "הזמן שנותר",
    sendAgain: "שלח שוב",
    wrongEmailAddress: "כתובת אימייל שגויה?",
    choosePassword: "בחר סיסמה",
    confirmPassword: "אשר סיסמה",
    savePassword: "שמור סיסמה",
    pleaseEnterPassword: "אנא הזן את הסיסמה שלך וחשבונך יירשם אלינו ותוכל להתחבר.",
    pleaseNote: "שימו לב: האימייל תקף ל-24 שעות",
    resetPassword: "איפוס סיסמה",
    newPassword: "סיסמה חדשה",
    addUser: "הוסף משתמש",
    add: "הוסף",
    setPassword: "הגדר סיסמה",
    item: "פריט",
    addSuccessfully: "נוסף בהצלחה",
    updateSuccessfully: "עודכן בהצלחה",
    errorMessage: "אופס, משהו השתבש, נסה שוב",
    questionSubmittedSuccess: "שאלה הוגשה בהצלחה",
    deletedSuccess: "נמחק בהצלחה",
    resetPasswordSuccessMsg: "קישור לאפס סיסמה נשלח לדוא’ל שלך בהצלחה.",
    successLogin: "נכנסת בהצלחה",
    otpSuccessMsg: "OTP שלח לדואר שלך בהצלחה",
    alreadyRegistered: "כבר רשום!",
    otpVerifiedMsg: "OTP אומת בהצלחה!",
    otpSentSuccessMsg: "OTP נשלח בהצלחה!",
    registerSuccessMsg: "הירשם בהצלחה !",
    resetPasswordLinkExpiredMsg: "פג תוקף הקישור לאיפוס סיסמה, נסה שוב",
    passwordResetSuccessMsg: "איפוס סיסמה בהצלחה!",
    subscription: "מנוי",
    noActiveSubscription: "אין מנוי פעיל",
    purchase: "רכישה",
    plan: "תוכנית",
    planDescription: "מנוי חודשי עם גישה לניסיונות מקסימום 10 שאלות",
    buy: "קנה",
    holdersName: "שם המחזיק",
    creditCardNumber: "מספר כרטיס אשראי",
    expiration: "תפוגה",
    save: "שמור",
    country: "מדינה",
    subscriptionSuccessMsg: "המנוי נוסף בהצלחה",
    recordNotFound: "הרשומה לא נמצאה",
    card: "כרטיס",
    cardDetailSuccessMsg: "פרטי הכרטיס עודכנו בהצלחה",
    questionAddedSuccessMsg: "שאלה נוספה בהצלחה",
    questionDeletedSuccessMsg: "שאלה נמחקה בהצלחה",
    totalCredit: "סך הכל אשראי",
    totalCredits: "סך הכל קרדיטים",
    creditHistory: "היסטוריית אשראי",
    creditsHistory: "היסטוריית קרדיטים",
    subscriptionCredit: "זיכוי מנוי",
    freeCredit: "קרדיט חינם",
    usedCredit: "אשראי משומש",
    usedCredits: "קרדיטים משומשים",
    pendingCredit: "אשראי בהמתנה",
    pendingCredits: "קרדיטים בהמתנה",
    note: "הערה",
    credit: "קרדיט",
    credits: "קרדיטים",
    test: "מבחן",
    type: "סוג",
    testCredit: "קרדיט מבחן",
    amount: "כמות",
    status: "סטטוס",
    startDate: "תאריך התחלה",
    endDate: "תאריך סיום",
    newSubscription: "מנוי חדש",
    testFreeCredit: "בדוק אשראי חינם",
    expiryDate: "תאריך תפוגה",
    subscribed: "הירשם כמנוי",
    unsubscribed: "בטל מנוי",
    profile: "פרופיל",
    account: "חשבון",
    wallet: "ארנק",
    exportToPDF: "יצוא ל-PDF",
    reportProcessMessage: "הכנת הדוח שלך בתהליך. תקבל אימייל ברגע שזה נעשה.",
    update: "עדכון",
    emailChangeOTPSuccessMsg: "OTP נשלח בהצלחה!",
    emailChangedSuccessMsg: "הדוא’ל עודכן בהצלחה, התחבר שוב",
    currentPassword: "סיסמה נוכחית",
    passwordUpdateSuccessMsg: "הסיסמה עודכנה בהצלחה",
    date: "תאריך",
    deleteTestErrorMessage: "הבדיקה שאליו אתה מנסה לגשת כבר לא קיימת או נמחקה.",
    registerDate: "תאריך רישום",
    lastLoginDate: "תאריך כניסה אחרון",
    purchasedPlan: "תוכנית רכשה",
    planStatus: "סטטוס תוכנית",
    purchasedPlanStartDate: "תאריך התחלה של תוכנית שנרכשה",
    purchasedPlanRenewDate: "תאריך חידוש תוכנית שנרכשה",
    creator: "בורא",
    isReportGenerated: "נוצר דוח",
    planSubscriptionSuccessMessage: "תוכנית המנוי נרשמה בהצלחה",
    planUnsubscribedSuccessMessage: "תוכנית המנוי בוטלה בהצלחה",
    yesLogout: "כן, התנתק",
    yesDelete: "כן, מחק",
    completedReports: "דוחות שהושלמו",
    pendingReports: "דוחות ממתינים",
    freeAssociationPhase: "שלב ההתאגדות החופשית",
    inquiryPhase: "שלב החקירה",
    reportIsInProgress: "הדוח נמצא בתהליך",
    addMore: "הוסף עוד",
    subscribeAgainPlanMessage: "אתה יכול להירשם שוב לתוכנית שלך ב",
    autoRenewPlanMessage: "התוכנית שלך תתחדש אוטומטית בשעה",
    cardInformation: "מידע על כרטיס",
    deleteQuestionMessage: "האם אתה בטוח שברצונך למחוק שאלה?",
    leaveQuestionMessage: "האם אתה רוצה לשמור שאלות?",
    logoutMessage: "האם אתה בטוח שברצונך להתנתק?",
    language: "שָׂפָה",
    english: "English",
    hebrew: "עִברִית",
    summary: "תַקצִיר",
    deleteUserMessage: "האם אתה בטוח שברצונך למחוק משתמש זה?",
    deleteTestReportMessage: "האם אתה בטוח שברצונך למחוק את דוח הבדיקה הזה?",
    generateStrongPassword: "צור סיסמה חזקה",
    testCreatedSuccessMessage: "הבדיקה נוצרה בהצלחה",
    planStatusUpdateFirstMessage: "אתה בטוח שאתה רוצה",
    plaStatusUpdateSecondMessage: "את התוכנית?",
    expired: "פג תוקף",
    twentyUsdPlanNote: "<p>כל חודש יתווסף זיכוי 20 לחשבון שלך.</p><p>מנוי חודשי בסך 20 דולר עם גישה לניסיונות מקסימום 20 בדיקות.</p>",
    fiftyUsdPlanNote: "<p>כל חודש יתווסף זיכוי של 100 לחשבון שלך.</p><p>מנוי חודשי בסך 50 דולר עם גישה לניסיונות מקסימום 100 בדיקות.</p>",
    monthlyPlan: "תוכנית חודשית",
    clear: "בָּרוּר",
    done: "נַעֲשָׂה",
    male: "זָכָר",
    female: "נְקֵבָה",
    addedFreeCredit: "נוסף אשראי חינם למשתמש",
    newSubscriptionAfterRenew: "מנוי חדש לאחר חידוש",
    expiredSubscription: "פג תוקף המנוי שלך",
    signInWithMicrosoft: "היכנס עם Microsoft",
    signInWithGoogle: "היכנס באמצעות גוגל",
    goBack: "לַחֲזוֹר",
    passwordResetLinkErrorMessage: "פג תוקף הקישור לאיפוס הסיסמה שלך. אם אתה עדיין רוצה לאפס את הסיסמה שנשכחה, ​​אנא הזן את כתובת הדוא\"ל שלך.",
    select: 'לִבחוֹר',
};

export default {
    locale: "he-IL",
    messages: {
        title: titles
    },
};
