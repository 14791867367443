<template>
    <header class="bg-white main-header">
        <div class="container">
            <div class="row">
                <div class="col-auto d-flex align-items-center">
                    <router-link v-if="user?.role == 'user'" :to="{name:'test'}"><img src="../assets/logo.svg"
                                                                                      alt="logo" width="89" />
                    </router-link>
                    <router-link v-else to="/"><img src="../assets/logo.svg" alt="logo" width="89" /></router-link>
                </div>
                <div class="ms-auto col-auto text-end d-flex align-items-center" id="profile-icon-header" >
                    <span class="me-2">
                        <b-dropdown right class="m-0 account pb-point-1 ms-2" :text="selectedTitle">
                                <b-dropdown-item
                                    class="notify-item"
                                    v-for="(entry, i) in languages"
                                    :key="`Lang${i}`"
                                    :value="entry.language"
                                    :class="{ 'active': entry.language === selectedLanguage }"
                                    @click="setLanguage(entry.language, entry.title)"
                                >
                                    <span class="align-middle">{{ entry.title }}</span>
                                </b-dropdown-item>
                        </b-dropdown>
                         <b-dropdown id="dropdown-right" right :text="$t('title.account')"
                                     class="m-0 account pb-point-1 ms-2" v-if="user && user?.role !== 'admin'">
                            <b-dropdown-item :class="{'active': setActiveTab('subscription')}" :to="{name:'subscription'}">
                                {{$t('title.subscription') }}
                            </b-dropdown-item>
                            <b-dropdown-item :class="{'active': setActiveTab('wallet')}" :to="{name:'wallet'}">
                                {{$t('title.wallet') }}
                            </b-dropdown-item>
                             <b-dropdown-item v-if="platform == 'ios' || platform == 'android'"
                                              class="delete-account"
                                              @click.prevent="openCloseDeleteAccountModal"
                             >
                                {{$t('title.delete') }}
                            </b-dropdown-item>
                         </b-dropdown>

                        <router-link :to="{name:'profile'}" v-if="user && user?.role !== 'admin'" class="ms-2">
                            <i class="bi bi-person"></i> {{$t('title.profile') }}
                        </router-link>
                        <router-link :to="{name:'adminProfile'}" v-if="user && user?.role === 'admin'" class="ms-2">
                            <i class="bi bi-person"></i> {{$t('title.profile') }}
                        </router-link>
                        <span v-if="user" @click.prevent="openCloseLogoutModal" role="button" class="ms-2">
                            <strong><i class="bi bi-power"></i></strong>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <logout-modal ref="logoutModal" />
        <delete-account-modal ref="deleteAccountModal"/>
    </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LogoutModal from "@/views/auth/modal/logoutModal";
import DeleteAccountModal from "@/views/auth/modal/deleteAccountModal";
import {request} from "@/Util/Request";
import {getCookie, refresh, setCookie} from "@/Util/auth";

export default {
    name: 'AppHeader',
    components: { LogoutModal, DeleteAccountModal },
    data() {
        return {
            activeTab : '',
            platform: '',
            selectedLanguage: 'en-US',
            selectedTitle: 'English',
            languages: this.updatedLanguages()
        };
    },
    mounted() {
        this.platform = (getCookie('platform')) ?? '';
        this.selectedLanguage = (getCookie('locale')) ?? this.selectedLanguage;
        this.selectedTitle = this.updatedLanguages().find(entry => entry.language === this.selectedLanguage).title;
    },
    methods: {
        ...mapActions(['setIsLogout']),
        updatedLanguages() {
          return [
              {
                  language: "en-US",
                  title: this.$t('title.english'),
              },
              {
                  language: "he-IL",
                  title: this.$t('title.hebrew'),
              },
          ];
        },
        async openCloseLogoutModal() {
            this.setIsLogout(true);
            this.$refs.logoutModal.handleToggleModal();
        },
        async openCloseDeleteAccountModal() {
            this.$refs.deleteAccountModal.handleToggleModal();
        },
        setActiveTab(tab) {
            return this.$route.name == tab;
        },
        setLanguage(locale, title) {
            this.$store.commit('CHANGE_SETTING', locale);
            setCookie(`locale`, locale);
            refresh();
        },
    },
    computed: {
        ...mapGetters(['user'])
    },
    watch: {
        '$i18n.locale': {
            handler(newLocale) {
                this.languages =  this.updatedLanguages();
                this.selectedLanguage = (getCookie('locale')) ?? this.selectedLanguage;
                this.selectedTitle = this.updatedLanguages().find(entry => entry.language === this.selectedLanguage).title;
            },
            immediate: true, // Run on initial load
        },
    },
};
</script>
