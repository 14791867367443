<template>
    <div>
        <b-modal v-model="isOpenModal" id="logoutModal"  :hide-header="true"
                 :hide-footer="true">
                <p>{{ $t('title.logoutMessage') }}</p>
                <div class="modal-footer d-flex mr-2 px-0 gap-3">
                    <button type="button" class="btn main-btn col m-0" @click="handleSubmitLogout">{{ $t('title.yesLogout') }}</button>
                    <button type="button" class="btn sec-btn col m-0" @click="cancelLogout">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
        </b-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { removeCookie } from "@/Util/auth";


export default {
    data() {
        return {
            isOpenModal: false,
        };
    },
    methods: {
        ...mapActions(['setIsLogout']),
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitLogout() {
            let role = 'user';
            try {
                removeCookie('auth');
                removeCookie('platform');
                role = this.user?.role;

                await this.$store.dispatch('user', null);
                await this.$router.replace({ name: (role == 'admin') ? 'adminLogin' : 'login' });
                this.handleToggleModal();
                this.setIsLogout(false);

            } catch (error) {
                this.setIsLogout(false);
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        cancelLogout() {
            this.isOpenModal = false;
            this.setIsLogout(false);
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>
