<template>
    <div>
        <b-modal v-model="isOpenModal" id="deleteTestModal"  :hide-header="true"
                 :hide-footer="true">
                <p>{{ $t('title.deleteTestReportMessage') }}</p>
                <div class="modal-footer d-flex mr-2 gap-2">
                    <button type="button" :disabled="isDeleteTestReport" class="btn main-btn col m-0" @click="handleSubmitDeleteReport">{{ $t('title.yes') }}</button>
                    <button type="button" class="btn sec-btn col m-0" @click="handleToggleModal">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
        </b-modal>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";

export default {
    data() {
        return {
            isOpenModal: false,
            deleteUrl: null,
            formErrors: new Error({}),
            isDeleteTestReport: false
        };
    },
    methods: {
        handleToggleModal(url) {
            this.isOpenModal = !this.isOpenModal;
            this.deleteUrl = url;
        },
        async handleSubmitDeleteReport() {
            try {
                this.isDeleteTestReport = true;
                const response = await request({
                    method: 'delete',
                    url: this.deleteUrl,

                });
                this.isOpenModal = false;
                this.notifySuccessWithMsg(`${ this.$t('title.test') } ${ this.$t('title.deletedSuccess') }!`);
                this.$emit('handleRefreshList');
                this.isDeleteTestReport = false;
            } catch (error) {
                this.isDeleteTestReport = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
    }
};
</script>
