const titles = {
    action: 'Action',
    title: 'Title',
    addNewTest: 'Add new test',
    idNumber: "ID number",
    dob: "Date of birth",
    enterToSearch: "Enter to search",
    edit: "Edit",
    delete: "Delete",
    show: "Show",
    birthday: "Birthday",
    gender: "Gender",
    dateCompleted: "Date Completed",
    tests: 'Tests',
    backToReports: "Back to Reports",
    report: "Report",
    close: "Close",
    showCodes: "Show Codes",
    generateReport: "Generate Report",
    logout: "Logout",
    cancel: "Cancel",
    createNewTest: "Create new test",
    numberOfQuestions: "Number of Questions",
    name: "Name",
    email: "Email",
    isVerified: "Is Verified?",
    isVisitFormLink: "Is Form Link?",
    users: "Users",
    yes: "Yes",
    no: "No",
    back: "Back",
    role: "Role",
    isLogin: "Is Login?",
    fullName: "Full Name",
    password: "Password",
    passwordConfirmation: "Password Confirmation",
    saveAndClose: "Save and Close",
    user: "User",
    addNewUser: "Add New User",
    submit: "Submit",
    backToLogin: "Back To Login",
    forgotPassword: "Forgot Your Password?",
    forgotPasswordMsg: "Enter your email address and we’ll send you a link to reset your password",
    isActive: "Is active?",
    signIn: "Sign In",
    rememberMe: "Remember Me",
    login: "Log in",
    forgotYourPassword: "Forgot your password?",
    or: "Or",
    newToInklyze: "New To Inklyze?",
    signUp: "Sign Up",
    alreadyHavingAccount: "Already Having Account?",
    pass: "Pass",
    otp: "OTP",
    next: "Next",
    verifyYourEmail: "Verify your email",
    weSentVerificationCode: "We sent a 4-digit verification code to",
    didNotGetCode: "Didn’t get a code?",
    secondsLeft: "Time remaining",
    sendAgain: "Send again",
    wrongEmailAddress: "Wrong email address?",
    choosePassword: "Choose Password",
    confirmPassword: "Confirm Password",
    savePassword: "Save Password",
    "pleaseEnterPassword": "Please enter your password and your account will register with us and you can able to login.",
    "pleaseNote": "Please note: Email is valid for 24 hours",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    addUser: "Add User",
    add: "Add",
    setPassword: "Set Password",
    item: "Item",
    addSuccessfully: "Added Successfully",
    updateSuccessfully: "Updated Successfully",
    errorMessage: "Ops, something went wrong, try again",
    questionSubmittedSuccess: "Question Submitted Successfully",
    deletedSuccess: "deleted successfully",
    resetPasswordSuccessMsg: "Reset password link has been sent to your email successfully.",
    successLogin: "You have been successfully logged in",
    otpSuccessMsg: "OTP send to your mail successfully",
    alreadyRegistered: "Already Registered!",
    otpVerifiedMsg: "OTP Verified Successfully !",
    otpSentSuccessMsg: "OTP Sent Successfully !",
    registerSuccessMsg: "Register Successfully !",
    resetPasswordLinkExpiredMsg: "Reset password link is expired,try again",
    passwordResetSuccessMsg: "Password Reset Successfully !",
    subscription: "Subscription",
    noActiveSubscription: "No active subscription",
    purchase: "Purchase",
    plan: "Plan",
    planDescription: "monthly subscription with access to attempts maximum 10 questions",
    buy: "Buy",
    holdersName: "Holders Name",
    creditCardNumber: "Credit Card Number",
    expiration: "Expiration",
    save: "Save",
    country: "Country",
    subscriptionSuccessMsg: "Subscription Successfully added",
    recordNotFound: "Record Not Found",
    card: "Card",
    cardDetailSuccessMsg: "Card Detail Successfully updated",
    questionAddedSuccessMsg: "Question Added Successfully",
    questionDeletedSuccessMsg: "Question Deleted Successfully",
    totalCredit: "Total Credit",
    totalCredits: "Total Credits",
    creditHistory: "Credit History",
    creditsHistory: "Credits History",
    subscriptionCredit: "Subscription Credit",
    freeCredit: "Free Credit",
    usedCredit: "Used Credit",
    usedCredits: "Used Credits",
    pendingCredit: "Pending Credit",
    pendingCredits: "Pending Credits",
    note: "Note",
    credit: "Credit",
    credits: "Credits",
    test: "Test",
    type: "Type",
    testCredit: "Test Credit",
    amount: "Amount",
    status: "Status",
    startDate: "Start Date",
    endDate: "End Date",
    newSubscription: "New Subscription",
    testFreeCredit: "Test Free Credit",
    expiryDate: "Expiry Date",
    subscribed: "Subscribe",
    unsubscribed: "Unsubscribe",
    profile: "Profile",
    account: "Account",
    wallet: "Wallet",
    exportToPDF: "Export to PDF",
    reportProcessMessage: "Your report preparation is under process. You will get an email once it done.",
    update: "Update",
    emailChangeOTPSuccessMsg: "OTP Sent Successfully !",
    emailChangedSuccessMsg: "Email updated successfully, Please login again!",
    currentPassword: "Current Password",
    passwordUpdateSuccessMsg: "Password updated successfully",
    date: "Date",
    deleteTestErrorMessage: "The test you are trying to access no longer exists or has been deleted.",
    registerDate: "Register Date",
    lastLoginDate: "Last Login Date",
    purchasedPlan: "Purchased Plan",
    planStatus: "Plan Status",
    purchasedPlanStartDate: "Purchased Plan Start Date",
    purchasedPlanRenewDate: "Purchased Plan Renew Date",
    creator: "Creator",
    isReportGenerated: "Is Report Generated",
    planSubscriptionSuccessMessage: "Subscription plan subscribed successfully",
    planUnsubscribedSuccessMessage: "Subscription plan unsubscribed successfully",
    yesLogout: "Yes, Logout",
    yesDelete: "Yes, Delete",
    completedReports: "Completed Reports",
    pendingReports: "Pending Reports",
    freeAssociationPhase: "Free Association Phase",
    inquiryPhase: "Inquiry Phase",
    reportIsInProgress: "Report is in-progress",
    addMore: "Add More",
    subscribeAgainPlanMessage: "You are able to subscribe again your plan at",
    autoRenewPlanMessage: "Your plan will auto renew at",
    cardInformation: "Card Information",
    deleteQuestionMessage: "Are you sure you want to delete question?",
    leaveQuestionMessage: "Do you want to save questions?",
    logoutMessage: "Are you sure you want to logout?",
    language: "Language",
    english: "English",
    hebrew: "Hebrew",
    summary: "Summary",
    deleteUserMessage: "Are you sure you want to delete this user?",
    deleteTestReportMessage: "Are you sure you want to delete this test report?",
    generateStrongPassword: "Generate Strong Password",
    testCreatedSuccessMessage: "Test created successfully",
    planStatusUpdateFirstMessage: "Are you sure you want to",
    plaStatusUpdateSecondMessage: "the plan?",
    expired: "Expired",
    twentyUsdPlanNote: "<p>Every month 20 credit will be added to your account.</p><p>20 USD monthly subscription with access to attempts maximum 20 tests.</p>",
    fiftyUsdPlanNote: "<p>Every month 100 credit will be added to your account.</p><p>50 USD monthly subscription with access to attempts maximum 100 tests.</p>",
    monthlyPlan: "Monthly Plan",
    clear: "Clear",
    done: "Done",
    male: "Male",
    female: "Female",
    addedFreeCredit: "Added Free Credit For User",
    newSubscriptionAfterRenew: "New Subscription After Renew",
    expiredSubscription: "Expired Your Subscription",
    signInWithMicrosoft: "Sign in with Microsoft",
    signInWithGoogle: "Sign in with Google",
    goBack: "Go back",
    passwordResetLinkErrorMessage: "Your password reset link has expired. If you still wish to reset your forgotten password please enter your email address.",
    select: 'Select',
};

export default {
    locale: "en-US",
    messages: {
        title: titles
    },
};
