<template>
    <div class="forgotpass w-100 d-flex pt-5">
        <section class="forgotpass col-12 mx-auto align-self-md-center col-md-7 col-xl-5">
            <div class="half-design row">
                <div class="col-12 form-block p-md-4 p-3">
                    <div class="col-auto mb-2 d-block sec-color">
                        <router-link :to="{name:'login'}" class="sec-color text-decoration-none">
                            <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="me-2"
                            >
                                <path
                                    d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM12 3.5L1 3.5L1 4.5L12 4.5L12 3.5Z"
                                ></path>
                            </svg>
                            {{ $t('title.backToLogin') }}
                        </router-link>
                    </div>
                    <form action="" @submit.prevent="handleSubmit">
                        <h3 class="mb-3">{{ $t('title.forgotPassword') }}</h3>
                        <p class="fs-18 mb-4 grey-info">
                            {{ $t('title.forgotPasswordMsg') }}
                        </p>
                        <div class="input-group mb-2 has-validation">
                            <div
                                :class="formErrors.first('email') ? `is-invalid form-floating` : `form-floating`"
                            >
                                <input
                                    type="email"
                                    id="email"
                                    class="form-control base-input"
                                    placeholder="name@example.com"
                                    v-model="formFields.email"
                                    @focus="removeError"
                                />
                                <label for="email" class="input-grey fs-14">{{ $t('title.email') }}</label>
                                <svg class="icon icon-alert-triangle" width="16" height="16">
                                    <use :href="icons + '#icon-alert-triangle'"></use>
                                </svg>
                                <svg class="icon icon-check" width="16" height="16">
                                    <use :href="icons + '#icon-check'"></use>
                                </svg>
                            </div>
                            <div class="invalid-feedback m-0" v-if="formErrors.get('email')">
                                <span v-for="(error, index) in formErrors.get('email')" :key="index">{{
                                        error
                                    }}
                                </span>
                            </div>
                        </div>
                        <button type="submit" class="btn main-btn d-block fs-20 w-100 py-3" :disabled="loader">
                            <i class="fa fa-spinner fa-spin" v-if="loader" />
                            {{ $t('title.submit') }}
                        </button>
                    </form>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import icons from '@/assets/icons.svg';
import Error from "@/Util/Error";
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import { getAuthUser, hasAuthUser } from "@/Util/auth";

const DEFAULT_FORM_STATE = {
    email: '',
};

export default {
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            formErrors: new Error({}),
            icons: icons,
        };
    },
    mounted() {
        if ( hasAuthUser() ) {
            if ( getAuthUser().role !== 'admin' ) {
                this.$router.push({ name: 'dashboard' });
            } else {
                this.$router.push({ name: 'adminDashboard' });
            }
        }
    },
    methods: {
        async handleSubmit() {
            try {
                const response = await request({
                    method: 'POST',
                    url: `/forgot-password`,
                    data: this.formFields,
                });

                const { data } = response;

                this.formFields = { ...DEFAULT_FORM_STATE };

                this.notifySuccessWithMsg(this.$t('title.resetPasswordSuccessMsg'));

                this.$router.push({ name: 'login' });

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        async removeError(key) {
            this.formErrors = new Error({});
        },
        async cancel() {
            await this.$router.push({ name: "login" });
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
