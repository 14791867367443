<template>
    <div>
        <b-modal v-model="isOpenModal" id="planStausUpdateModal"  :hide-header="true"
                 :hide-footer="true">
                <p>{{ $t('title.planStatusUpdateFirstMessage') }} {{_.toLower(status)}} {{ $t('title.plaStatusUpdateSecondMessage') }} </p>
                <div class="modal-footer d-flex mr-2 gap-2">
                    <button type="button" class="btn main-btn col m-0" @click="handleSubmit" :disabled="isDisabledSaveBtn" id="planYesBtn">
                        <i class="fa fa-spinner fa-spin" v-if="loader"></i>
                        {{ $t('title.yes') }}
                    </button>
                    <button type="button" class="btn sec-btn col m-0" @click="handleToggleModal" id="planCancelBtn">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
        </b-modal>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";
import moment from "moment-timezone";
import {mapGetters} from "vuex";

export default {
    props: {
        'subscription': {
            required: true
        }
    },
    data() {
        return {
            isOpenModal: false,
            status : '',
            isActive: '',
            formErrors: new Error({}),
            isDisabledSaveBtn: false
        };
    },
    mounted() {
        window.addEventListener("keydown", (event) => {
            if ( document.activeElement.className == 'modal-content' && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('planYesBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('planYesBtn') && event.key === 'Tab' && !event.shiftKey ) {
                console.log("active element...", document.activeElement)
                document.getElementById('planYesBtn').classList.remove('active');
                document.getElementById('planCancelBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('planCancelBtn') && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('planCancelBtn').classList.remove('active');
            }
        });
    },
    methods: {
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
            this.status = (this.subscription.is_active == 1) ? this.$t('title.unsubscribed') : this.$t('title.subscribed')
            this.isActive = (this.subscription.is_active == 1) ? 0 : 1;
        },
        async handleSubmit() {
            try {
                this.isDisabledSaveBtn = true;
                const response = await request({
                    method: 'post',
                    url:  `/subscriptions/${this.subscription.id}`,
                    data: {
                        'is_active' : this.isActive
                    }
                });

                if(this.status == this.$t('title.subscribed')) {
                    this.notifySuccessWithMsg(this.$t('title.planSubscriptionSuccessMessage'));
                } else {
                    this.notifySuccessWithMsg(this.$t('title.planUnsubscribedSuccessMessage'));
                }
                this.handleToggleModal();

                this.isDisabledSaveBtn = false;
                this.$emit('reload');
            } catch (error) {
                this.isDisabledSaveBtn = false;
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
