<template>
    <div>
        <b-modal v-model="isOpenModal" id="addTestModal" :tabindex="-1"  :title="$t('title.createNewTest')" :hide-header="true"
                 :hide-footer="true">
            <form @submit.prevent="handleSubmit" autocomplete="off">
                <div class="modal-header mb-2">
                    <h1 class="modal-title fw-bold fs-20" id="createTestModal">{{ $t('title.createNewTest')}}</h1>
                </div>
                <div class="modal-body mb-2">
                    <div class="row d-flex align-items-center mb-42">
                        <label for="id_number" class="col fw-bold">{{ $t('title.idNumber')}}:</label>
                        <div class="col-8 ps-0">
                            <b-form-group>
                                <b-form-input
                                    class="form-control base-input"
                                    id="id_number"
                                    name="id_number"
                                    v-model="formFields.id_number"
                                    type="text"
                                    @focus="removeError('id_number')"
                                    @paste.prevent
                                    @input.native="isNumber($event)"
                                    inputmode="numeric"
                                    maxlength="10"
                                    :state="((formErrors.has('id_number') ? false : null))"
                                />
                            </b-form-group>
                        </div>
                        <div class="ms-auto col-8 ps-0 mt-1">
                            <b-form-invalid-feedback :state="formErrors.has('id_number') ? false : null">
                                {{ formErrors.first('id_number') }}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center mb-42">
                        <span class="col fw-bold">{{ $t('title.gender')}}:</span>
                        <div class="col-8 ps-0">
                            <b-form-group>
                                <b-form-radio-group v-model="formFields.gender"
                                                    class="justify-content-between d-flex"
                                                    id="gender"
                                                    @input="removeError('gender')"
                                                    :options="[{text:$t('title.male'),value:'male'},{text:$t('title.female'),value:'female'}]"
                                                    :state="formErrors.has('gender') ? false : null"
                                                    name="radio-validation" plain>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="ms-auto col-8 ps-0">
                            <b-form-invalid-feedback :state="formErrors.has('gender') ? false : null">
                                {{ formErrors.first('gender') }}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center">
                        <label for="Birthday" class="col fw-bold">{{ $t('title.birthday')}}:</label>
                        <div class="col-8 ps-0">
                            <b-form-group>
                                <datetime
                                    input-id="Birthday"
                                    name="Birthday"
                                    @input="removeError('dob')"
                                    v-model="formFields.dob"
                                    value=""
                                    :class="((formErrors.has('dob') ? `is-invalid` : null))"
                                    :format="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    :max-datetime="maxDate"
                                >
                                    <template slot="button-cancel"> {{ $t('title.clear') }}</template>
                                    <template slot="button-confirm"> {{ $t('title.done') }}</template>
                                </datetime
                                >
                            </b-form-group>
                        </div>
                        <div class="ms-auto col-8 ps-0 mt-1">
                            <b-form-invalid-feedback :state="formErrors.has('dob') ? false : null">
                                {{ formErrors.first('dob') }}
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-column gap-2">
                    <button :disabled="isSubmitButton" type="submit" id="saveBtn" class="btn main-btn w-100 m-0">{{
                            $t('title.createNewTest')
                        }}
                    </button>
                    <button type="button" id="cancelBtn" class="btn sec-btn w-100 m-0" @click="handleToggleModal">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";
import moment from "moment-timezone";

const FORM_STATE = {
    id_number: null,
    dob: null,
    gender: null
};

export default {
    data() {
        return {
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            isOpenModal: false,
            maxDate: moment().endOf('day').toISOString(),
            isSubmitButton: false
        };
    },
    mounted() {
        window.addEventListener("keydown", (event) => {
            if ( document.activeElement.className == 'modal-open' && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('saveBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('saveBtn') && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('saveBtn').classList.remove('active');
                document.getElementById('cancelBtn').classList.add('active');
            }

            if ( document.activeElement == document.getElementById('cancelBtn') && event.key === 'Tab' && !event.shiftKey ) {
                document.getElementById('cancelBtn').classList.remove('active');
            }
        });
    },
    methods: {
        isNumber(evt) {
            evt = evt || window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            const value = evt.target.value.replace(/[^0-9]/g, '');

            if ( charCode > 31 && ( charCode < 48 || charCode > 57 ) || value.length >= 10 ) {
                evt.preventDefault();
            } else {
                this.formFields.id_number = `${ value.toString().slice(0, 9) }`;
                return true;
            }
        },
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
            this.emptyFormFields();
            this.removeErrors();
        },
        async handleSubmit() {
            this.isSubmitButton = true;
            if ( !_.isEmpty(this.formFields.dob) ) {
                this.formFields.dob = moment(this.formFields.dob).format("YYYY-MM-DD");
            }

            try {
                const response = await request({
                    method: 'post',
                    url: '/tests',
                    data: this.formFields
                });

                if(response && response.data && response.data.id) {
                    this.$store.dispatch('setActiveTab', 'uncompleted');
                    this.$toast.success(this.$t('title.testCreatedSuccessMessage'));
                    this.handleToggleModal();
                    this.$router.push({'name':'testForm',params:{id:response.data.id,operation:'edit'}});
                }
                this.$emit('refresh-list');
                this.isSubmitButton = false;
            } catch (error) {
                this.isSubmitButton = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        removeErrors() {
            this.formErrors = new Error({});
        },
        emptyFormFields() {
            this.formFields = { ...FORM_STATE };
        }
    },
    watch: {
        // Watch for changes in modal state
        isOpenModal(newVal) {
            this.$nextTick(() => {
                const modal = document.getElementById('addTestModal');
                if (newVal) {
                    // Remove 'inert' when modal is open
                    if (modal) modal.removeAttribute('inert');
                } else {
                    // Add 'inert' when modal is closed
                    if (modal) modal.setAttribute('inert', '');
                }
            });
        }
    }
};
</script>
