<template>
    <div class="pt-5" id="subscriptionPlans">
        <div class="col-12 text-center py-2" v-if="loader">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <b-row class="mb-5">
            <b-col class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="(plan,index) in plans" :key="index">
                <b-card class="h-100" body-class="d-flex flex-column">
                    <b-card-header class="text-center">
                        {{ (plan.title == 'monthly_plan') ? $t('title.monthlyPlan')  : _.capitalize(plan.title.replace("_", " ")) }}
                        <p  v-if="currentMonthSubscription && currentMonthSubscription.plan_id == plan.id">
                            <b>{{ $t('title.expiryDate') }} :- <span
                                class="text-danger">{{ formattedEndDate }}</span>
                            </b>
                        </p>
                    </b-card-header>
                    <b-card-body>
                        <div class="m-auto text-center" v-html="(plan.amount == 20) ? $t('title.twentyUsdPlanNote') : (plan.amount == 50) ? $t('title.fiftyUsdPlanNote') : plan.notes">
                        </div>
                        <div v-if="currentMonthSubscription && currentMonthSubscription.plan_id == plan.id" class="mt-3">
                            <p v-if="currentMonthSubscription" class="text-danger">
                            <span v-if="currentMonthSubscription.is_active == 0 && !subscription">
                               {{ $t('title.subscribeAgainPlanMessage') }} {{ formattedRenewDate }} .
                            </span>
                                <span v-if="currentMonthSubscription.is_active == 1">
                                {{ $t('title.autoRenewPlanMessage') }} {{ formattedRenewDate }} .
                            </span>
                            </p>
                        </div>
                    </b-card-body>
                    <b-card-footer class="text-center mt-auto">
                        <credit-card    v-if=loadSubscribeButton
                                        :expiredSubscription="expiredSubscription"
                                        :plan="plan"
                                        v-on:reload="getProfile"
                                        :subscription="subscription"
                                        :currentMonthSubscription="currentMonthSubscription"
                                        :card-detail="card_detail"></credit-card>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" xs="12" class="plan-history">
                <b-card>
                    <b-card-header>{{ $t('title.subscription') }}</b-card-header>
                    <b-card-body>
                        <div v-if="!subscriptionHistories">
                            {{ $t('title.noActiveSubscription') }}
                        </div>
                        <div class="table-responsive" v-if="subscriptionHistories">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('title.status') }}</th>
                                        <th>{{ $t('title.amount') }}</th>
                                        <th>{{ $t('title.startDate') }}</th>
                                        <th>{{ $t('title.endDate') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(sub,subIndex) in subscriptionHistories" :key="subIndex">
                                        <td>{{ subIndex + 1 }}</td>
                                        <td>
                                            <span class="badge text-danger text-capitalize"
                                                  v-if="sub.expired_at">
                                                {{$t('title.expired')}}
                                            </span>
                                            <span v-else>
                                                <span class="badge text-black text-capitalize">
                                                {{(sub.is_active == 0) ? $t('title.unsubscribed') : $t('title.subscribed')}}
                                                </span>
                                            </span>
                                        </td>
                                        <td>{{ ( sub.plan || {} ).amount }} {{
                                                ( sub.plan.currency || {} ).code
                                            }}
                                        </td>
                                        <td>{{ sub.latest_subscription_history.start_date }}</td>
                                        <td>{{ sub.latest_subscription_history.end_date }}</td>
                                    </tr>
                                </tbody>
                                <tfoot v-if="!subscriptionHistories.length">
                                    <tr>
                                        <td colspan="5" align="center">
                                            {{ $t('title.recordNotFound') }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CreditCard from "@/views/profile/partials/creditCard";
import { request } from "@/Util/Request";
import { format, addDays } from 'date-fns';
import {mapGetters} from "vuex";

export default {
    components: {
        CreditCard,
    },
    data() {
        return {
            subscription: null,
            subscriptionHistories: [],
            monthly_plan: null,
            card_detail: null,
            wallet: null,
            currentMonthSubscription: null,
            plans: [],
            expiredSubscription: null,
            loadSubscribeButton:false,
        };
    },
    mounted() {
        this.getPlans();
    },
    computed: {
        formattedStartDate(){
            let startDate = this.currentMonthSubscription.latest_subscription_history.start_date;
            return format(startDate, 'MMM dd yyyy');
        },
        formattedEndDate() {
            let endDate = this.currentMonthSubscription.latest_subscription_history.end_date;
            return format(endDate, 'MMM dd yyyy');
        },
        formattedRenewDate() {
            let endDate = addDays(this.currentMonthSubscription.latest_subscription_history.end_date, 1);
            return format(endDate, 'MMM dd yyyy');
        },
        ...mapGetters(['loader']),
    },
    methods: {
        async getPlans() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/plans',
                });

                const { data } = response;

                this.plans = data;

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            } finally {
                this.getProfile();
            }
        },
        async getProfile() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/auth/profile?cards=1',
                });

                const { data } = response;
                const { monthly_plan, card, wallet } = data;

                this.monthly_plan = monthly_plan;
                this.card_detail = card;
                this.wallet = wallet;

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            } finally {
                await this.getDetail();
            }
        },
        async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/subscriptions',
                });

                const { data } = response;
                this.subscription = data.active;
                this.subscriptionHistories = data.list;
                this.currentMonthSubscription = data.current_month_subscription;
                this.expiredSubscription = data.expired;
                this.loadSubscribeButton = true;
            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        }
    }
};
</script>
