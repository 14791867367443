<template>
    <div class="pt-5">
        <b-row>
            <b-col xs="12" class="mb-2 col-md-3">
                <b-card>
                    <b-card-header>
                        {{ $t('title.totalCredits') }}
                    </b-card-header>
                    <b-card-body>
                        {{ ( wallet || {} ).credit }}
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col xs="12" class="mb-2 col-md-9">
                <b-card>
                    <b-card-header>
                        {{ $t('title.creditsHistory') }}
                    </b-card-header>
                    <b-card-body>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('title.credits') }}</th>
                                        <th>{{ $t('title.type') }}</th>
                                        <th>{{ $t('title.usedCredits') }}</th>
                                        <th>{{ $t('title.pendingCredits') }}</th>
                                        <th>{{ $t('title.note') }}</th>
                                        <th>{{ $t('title.date') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(history,index) in (wallet || []).histories" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ history.credited }}</td>
                                        <td>
                                            <span v-if="!history.test && history.subscription">
                                                {{ $t('title.newSubscription') }}  {{ $t('title.credit') }}
                                            </span>
                                            <span v-if="!history.test && !history.subscription">
                                                {{ $t('title.freeCredit') }}
                                            </span>
                                            <span v-if="( history.test || {} ).id_number && history.subscription">
                                                {{ $t('title.testCredit') }} - {{ history.test.id_number }}
                                            </span>
                                            <span v-if="( history.test || {} ).id_number && !history.subscription">
                                                {{ $t('title.testFreeCredit') }} - {{ history.test.id_number }}
                                            </span>
                                        </td>
                                        <td>{{ history.used_credit }}</td>
                                        <td>{{ history.pending_credit }}</td>
                                        <td>
                                             <span v-if="history.message == 'Added Free Credit For User'">
                                                {{ $t('title.addedFreeCredit') }}
                                            </span>

                                            <span v-else-if="history.message == 'New Subscription'">
                                                {{ $t('title.newSubscription') }}
                                            </span>

                                            <span v-else-if="history.message == 'New Subscription After Renew'">
                                                {{ $t('title.newSubscriptionAfterRenew') }}
                                            </span>

                                            <span v-else-if="history.message == 'Expired Your Subscription'">
                                                {{ $t('title.expiredSubscription') }}
                                            </span>

                                            <span v-else>
                                                {{history.message}}
                                            </span>

                                        </td>
                                        <td>{{ $global.dateFormat(history.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { request } from "@/Util/Request";

export default {
    data() {
        return {
            wallet: null,
        };
    },
    mounted() {
        this.getProfile();
    },
    methods: {
        async getProfile() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/auth/profile?cards=1',
                });

                const { data } = response;
                const { wallet } = data;

                this.wallet = wallet;

            } catch (error) {
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
    }
};
</script>
